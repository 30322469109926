<template>
    <div class="register" v-loading="loading">
        <div class="register-center">
            <div class="register-container">
                <div class="register-left">
                    <div><img src="../../assets/img/register-bg.png" alt=""></div>
                    <p>WELCOME</p>
                    <span>欢迎注册！</span>
                </div>
                <div class="register-right">
                    <p class="register-title">注册企业账号</p>
                    <div class="form-news">
                        <div class="form-news-center">
                            <div class="register-list">
                                <p><i>*</i>企业名称</p>
                                <el-input v-model="form.name" placeholder="请输入企业名称"></el-input>
                            </div>
                            <div class="register-list">
                                <p><i>*</i>信用代码</p>
                                <el-input v-model="form.creditCode" placeholder="请输入信用代码"></el-input>
                            </div>
                        </div>
                        <div class="form-upload">
                            <p><i>*</i>营业执照</p>
                            <el-upload class="avatar-uploader" action="/" accept="image/jpeg,image/jpg,image/png" :show-file-list="false" ref="classImg" :http-request='submitUpload'>
                                <img v-if="form.businessLicense" :src="form.businessLicense" class="avatar">
                                <div v-else class="avater-tips">
                                    <img src="../../assets/img/uoload.png" alt="">
                                    <!-- <svg-icon icon-class="upload"/> -->
                                    <p>请上传</p>
                                </div>
                            </el-upload>
                        </div>
                    </div>
                    <div class="register-address">
                        <p><i>*</i>企业地址</p>
                        <div class="address-center">
                            <el-select v-model="form.provId" placeholder="请选择省" value-key="form.venueAreaId" @change="onRegion">
                                <el-option v-for="item in areaList" :key="item.id" :label="item.name" :value="item.id" ></el-option>
                            </el-select>
                            <el-select v-model="form.cityId" placeholder="请选择市" value-key="form.cityId" @change="onCity">
                                <el-option v-for="item in cityList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                            </el-select>
                            <el-select v-model="form.districtId" placeholder="请选择区" value-key="form.districtId" style="margin-right:0;" @change="onDistrict">
                                <el-option v-for="item in districtList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                            </el-select>
                        </div>
                        <el-input v-model="form.address" placeholder="请输入详细地址" style="width:100%;"></el-input>
                    </div>
                    <div class="register-list">
                        <p><i>*</i>手机号</p>
                        <el-input v-model="form.phone" placeholder="请输入手机号"></el-input>
                    </div>
                    <div class="register-list">
                        <p><i>*</i>验证码</p>
                        <div class="register-between">
                            <el-input v-model="form.code" placeholder="请输入验证码" style="width:80%;"></el-input>
                            <el-button class="button-code" :disabled='isDisabled' @click="sendCode">{{buttonText}}</el-button>
                        </div>
                    </div>
                    <div class="register-list">
                        <p><i>*</i>设置密码</p>
                        <el-input v-model="form.password" placeholder="请输入密码" show-password></el-input>
                    </div>
                    <div class="register-list">
                        <p><i>*</i>再次输入</p>
                        <el-input v-model="form.passwordAgain" placeholder="请输入密码" show-password></el-input>
                    </div>
                    <div class="register-bottom">
                        <div class="register-button" @click="onSubmit">确认提交</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { sendCode,savaCompany } from "../../api/register"
import { listArea } from "../../api/address"
import { upload } from "../../api/upload"
export default {
    data(){
        return{
            form:{
                name:"",
                creditCode:"",
                businessLicense:"",
                provId:"",
                cityId:"",
                districtId:"",
                address:"",
                phone:"",
                code:"",
                password:"",
                passwordAgain:""
            },
            options: [{
                value: '选项1',
                label: '石家庄市'
                }, {
                value: '选项2',
                label: '双皮奶'
                }, {
                value: '选项3',
                label: '蚵仔煎'
                }, {
                value: '选项4',
                label: '龙须面'
                }, {
                value: '选项5',
                label: '北京烤鸭'
            }],
            value: '',
            buttonText: '获取验证码',
            isDisabled: false, // 是否禁止点击发送验证码按钮
            flag: true,
            pid:100000,
            areaList:[],
            cityList:[],
            districtList:[],
            level: 1,
            loading: false,
        }
    },
    created(){
        this.level = 1
        this.listArea(true)
    },
    methods:{
        // 自定义的上传图片的方法
        async submitUpload({ file }) {
            // console.log(file.type)
            // 'image/png' || 'image/jpg' || 'image.jpeg' || 'image.JPG'
            if(file.type !== 'application/pdf'){
                this.loading = true
                // const isLt2M = file.size / 1024 / 1024 < (this.maxSize || 1)
                // if(!isLt2M) {
                //     this.$message.error(`大于${this.maxSize*1000 + this.formatBytes(this.maxSize*1024*1024)}图片不适合前台页显示，请重新压缩图片。`)
                //     return false
                // }
                let formData = new FormData()
                formData.append('file', file, file.name)
                let res = await upload(formData)
                this.upload_url = res.data.data.url
                if (res.data.code == 20000) {
                    // console.log(res)
                    this.loading = false
                    this.$emit('selectImg', {url:res.data.data.url })
                    this.form.businessLicense = res.data.data.url
                }
            }
            
        },

        //发送验证码
        sendCode () {
            let phone = this.form.phone
            if(phone == ""){
                this.$message.error('手机号不能为空')
                return false
            }else if(!/^1[23456789]\d{9}$/.test(phone)){
                this.$message.error('手机号格式不正确')
                return false
            }
            if (this.checkMobile(phone)) {
                sendCode({phone:phone}).then(res => {
                    this.$message.success(res.data.message)
                    let time = 60
                    this.buttonText = '已发送'
                    this.isDisabled = true
                    if (this.flag) {
                        this.flag = false;
                        let timer = setInterval(() => {
                            time--;
                            this.buttonText = time + ' 秒'
                            if (time === 0) {
                                clearInterval(timer);
                                this.buttonText = '重新获取'
                                this.isDisabled = false
                                this.flag = true;
                            }
                        }, 1000)
                    }
                });
            }
        },

        onRegion(value){
            // console.log('省',value)
            this.form.provId = value
            this.pid = value
            this.level = 2
            this.listArea()
        },

        onCity(value){
            // console.log('市',value)
            this.form.cityId = value
            this.pid = value
            this.level = 3
            this.listArea()
        },

        onDistrict(value){
            // console.log('区',value)
            this.form.districtId = value
        },

        //地区
        listArea(type){
            listArea({
                pid:this.pid
            }).then((res) => {
                if(res.data.code == 20000){
                    // console.log(this.level, res.data.data.areaList)
                    if (this.level == 1) {
                        this.areaList = res.data.data.areaList
                        if (type) {
                            this.level = 2
                            this.pid = res.data.data.areaList[0].id
                            // console.log(9999, this.pid)
                            this.listArea(type)
                        }
                    } else if (this.level == 2) {
                        this.cityList = res.data.data.areaList
                        if (type) {
                            this.level = 3
                            this.pid = res.data.data.areaList[0].id
                            // console.log(9999999, this.pid)
                            this.listArea(type)
                        }
                    } else if (this.level == 3) {
                        this.districtList = res.data.data.areaList
                    }
                }
            })
        },

        //确认提交
        onSubmit(){
            if(this.form.name == ""){
                this.$message.error('请输入企业名称')
                return false
            }else if(this.form.creditCode == ""){
                this.$message.error('请输入信用代码')
                return false
            }else if(this.form.businessLicense == ""){
                this.$message.error('请上传营业执照')
                return false
            }else if(this.form.districtId == ""){
                this.$message.error('请选择地址')
                return false
            }else if(this.form.address == ""){
                this.$message.error('请输入详细地址')
                return false
            }else if(this.form.phone == ""){
                this.$message.error('请输入手机号')
                return false
            }else if(this.form.code == ""){
                this.$message.error('请输入验证码')
                return false
            }else if(this.form.password == ""){
                this.$message.error('请输入密码')
                return false
            }else if(this.form.passwordAgain == ""){
                this.$message.error('请再次输入密码')
                return false
            }else if(!/^1[23456789]\d{9}$/.test(this.form.phone)){
                this.$message.error('手机号格式不正确')
                return false
            }else if(this.form.password !== this.form.passwordAgain){
                this.$message.error('两次密码输入的不一致')
                return false
            }else if(!/^[a-zA-Z0-9]{18}/.test(this.form.creditCode)){
                 this.$message.error('信用代码需要18位数字')
                return false
            }

            savaCompany(this.form).then((res) => {
                if(res.data.code == 20000){
                    this.$message.success("您提交的信息将在一个工作日内审核通过，敬请关注")
                    // this.$message.success(res.data.message)
                    this.$router.push("/login")
                }else if(res.data.code == 20033){
                    this.$message.error(res.data.message)
                    this.$router.push("/login")
                }else{
                    this.$message.error(res.data.message)
                }
            })
        },

        // 验证手机号
        checkMobile(str) {
            let re = /^1\d{10}$/;
            if (re.test(str)) {
                return true;
            } else {
                return false;
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.register-center{
    width:100%;
    background-color:#F8F8F8;
    padding:38px 0;
    box-sizing: border-box;
    .register-container{
        width:1200px;
        background-color:#FFFFFF;
        margin:auto;
        padding:0 114px 0 98px;
        display:flex;
        align-items:center;
        .register-left{
            width:470px;
            display:flex;
            flex-direction: column;
            align-items:center;
            div{
                width:470px;
                height:470px;
                display:flex;
                align-items:center;
                justify-content: center;
                img{
                    width:400px;
                    height:400px;
                }
            }
            p{
                font-size: 36px;
                font-weight: 900;
                color: #333333;
                line-height: 51px;
                background: linear-gradient(270deg, #FF6100 0%, #FFB800 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
            span{
                color:#333333;
                font-size:20px;
            }
        }
        .register-right{
            width:420px;
            margin-left:98px;
            .register-title{
                color:#333333;
                font-size:16px;
                font-weight:bold;
                padding-top:40px;
            }
            .form-news{
                margin-top:20px;
                display:flex;
                align-items:center;
                .form-news-center{
                    width:262px;
                }
                .form-upload{
                    width:148px;
                    margin-left: 10px;
                    margin-top:-13px;
                    i{
                        color:red;
                        margin-right:5px;
                    }
                    p{
                        color:#333333;
                        font-size:12px;
                        padding-bottom:8px;
                    }
                    .avatar-uploader{
                        width: 140px;
                        height: 115px;
                        background-color: #f2f2f2;
                        border-radius: 4px;
                        border: 1px dashed #D8D8D8;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        .avater-tips{
                            display:flex;
                            flex-direction: column;
                            align-items:center;
                            img{
                                width:15px;
                                height:15px;
                                border-radius:0;
                            }
                            p{
                                color:#999999;
                                font-size:12px;
                                padding-top:8px;
                            }
                        }
                        .avatar{
                            width:140px;
                            height:100%;
                            border-radius: 4px;
                        }
                    }
                }
            }
            .register-address{
                margin-bottom:15px;
                i{
                    color:red;
                    margin-right:5px;
                }
                p{
                    color:#333333;
                    font-size:12px;
                    padding-bottom:8px;
                }
                .address-center{
                    display:flex;
                    align-items:center;
                    justify-content: space-between;
                    margin-bottom:15px;
                }
                /deep/ .el-input{
                    width:98%;
                    margin-right:10px;
                }
                /deep/ .el-select{
                    margin-right:8px;
                }
            }
            .register-list{
                width:100%;
                margin-bottom:15px;
                i{
                    color:red;
                    margin-right:5px;
                }
                p{
                    color:#333333;
                    font-size:12px;
                    padding-bottom:8px;
                }
                .register-between{
                    display:flex;
                    align-items:center;
                    justify-content: space-between;
                    .button-code{
                        margin-left:10px;
                        background-color: #123E90;
                        color: #ffffff;
                    }
                }
            }
            .register-bottom{
                width:100%;
                display:flex;
                align-items:center;
                justify-content: flex-end;
                margin-bottom: 25px;
                margin-top: 40px;
                .register-button{
                    width: 111px;
                    height: 40px;
                    background: linear-gradient(270deg, #FF6100 0%, #FFB800 100%);
                    border-radius: 6px;
                    background-color:#FFFFFF;
                    font-size:14px;
                    display:flex;
                    align-items:center;
                    justify-content: center;
                    color:#FFFFFF;
                    font-size:14px;
                    cursor: pointer;
                }
            }
        }
    }
    /deep/ .el-upload{
        width:100%;
        height:100%;
        display:flex;
        align-items:center;
        justify-content: center;
    }
}
</style>