import ajax from "./ajax"

// 上传图片
export function upload(data){
    return ajax({
        url:'/api/file/uploadFile',
        method:'post',
        data
    })
}

//上传文件
export function uploadConfirmation(data){
    return ajax({
        url:'/api/booking/uploadConfirmation',
        method:'post',
        data
    })
}

//上传文件
export function uploadFile(data){
    return ajax({
        url:'/api/booking/uploadConfirmationInBookingTime',
        method:'post',
        data
    })
}